import React, { useState, useEffect, useContext } from "react";
import { ApiService, ApiLoginService } from "../ApiService/services";
import GBackIcon from "../Elements/gBackIcon";
import { useEnqueueSnackbar } from "../DataFactory/useCustomSanckbar";
import GButton from "../Elements/GButton";
import { GCardShimmer } from "../Elements/gShimmer";
import { GContent, GInlineContent, GTableContent } from "../Elements/gContent";
import Modal from "react-modal";
import CancelReasonsView from "../Components/orderCancelReasons";
import OrderFeedback from "../Components/orderFeedback";
import GText from "../Elements/GText";
import GView from "../Elements/GView";
import OrderMessages from "../Components/orderMesaages";
import { ReactComponent as NavigateIcon } from "../assets/images/navigation.svg";
import GInfo from "../Elements/gInfo";
import { StringsContext } from "../DataFactory/useStrings";
import { I18nManager } from "react-native";
import { RepeatOrder } from "../Models/RepeatOrder";
import { CartContext } from "../DataFactory/useCart";
import useCart from "../useCart";
import ModifyOrderAddress from "../forms/modifyOrderAddressForm";
import CartModal from "../Components/cartModal";
import OrderTrack from "../Components/orderGoogleMap";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "../DataFactory/useLocalStorage";
import GItemTable from "../Elements/gCartItems";

function getIOSVersion() {
  var match = window.navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
  return match ? match[1] : undefined;
}

export default function OrderDetail(props) {
  const navigate = useNavigate();
  const [strings] = useContext(StringsContext);
  const { updateCart, setStoreCart } = useContext(CartContext);
  const { setCart } = useCart();
  const [cartStore, setCartStore] = useLocalStorage("cartStore", "");
  const [orderData, setOrderData] = useState({});
  const [cartvisible, setCartvisible] = useState(false);
  const [ocrvisible, setOcrVisible] = useState(false);
  const [ofvisible, setOfvisible] = useState(false);
  const [omvisible, setOmvisible] = useState(false);
  const [oAdVisible, setOAdVisible] = useState(false);
  const [reviewType, setReviewType] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [orderMessages, setOrderMessages] = useState([]);
  const {
    status,
    store,
    delivery,
    order_products,
    payment_summary,
    order_review,
    order_delivery_review,
    cancelled_log,
  } = orderData;
  const enqueueSnackbar = useEnqueueSnackbar();
  const [routeJson, setRouteJson] = useState({});

  const getOrderDetail = () => {
    ApiService({ method: "GET", route: "c/orders/" + props.linkedId })
      .then((response) => {
        console.log(response);
        if (response.status === 26) {
          enqueueSnackbar(
            strings?.s_placeholder_internet_title +
              ". " +
              strings?.s_placeholder_internet_subtitle
          );
          return;
        } else {
          if (response?.data?.status_code === 1) {
            setOrderData(response.data.data);
            getRouteJson(response?.data?.data);
            setIsLoading(false);
            setOrderMessages(response.data.data?.order_messages || []);
          } else {
            enqueueSnackbar(response?.data?.message);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getOrderDetail();
  }, [props.linkedId]);

  const handleUpdateStatus = (status) => {
    ApiService({
      method: "POST",
      route: "c/order/" + status + "/" + props.linkedId,
    })
      .then((response) => {
        console.log(response);

        if (response.status === 26) {
          enqueueSnackbar(
            strings?.s_placeholder_internet_title +
              ". " +
              strings?.s_placeholder_internet_subtitle
          );
          return;
        } else {
          if (response?.data?.status_code === 1) {
            getOrderDetail();
            props.onStatusUpdate();
          } else {
            enqueueSnackbar(response?.data?.message);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function getRouteJson(o) {
    ApiLoginService(
      "GET",
      "c/order/directions/" +
        o?.delivery?.id +
        "?key=" +
        process.env.REACT_APP_GOOGLE_DIRECTION_KEY
    ).then((response) => {
      console.log(response);
      if (response?.routes) {
        setRouteJson(response || {});
      }
    });
  }

  if (props.orderInfovisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="modal-header">
        <GBackIcon onClick={() => props.setOrderInfovisible(false)} />{" "}
        {strings?.w_order_details}
        {orderData?.order_status === "pending" && (
          <GButton
            variant="linkable"
            children={strings?.w_problem}
            type="button"
            style={{
              marginLeft: I18nManager.getConstants().isRTL ? "0" : "auto",
              marginRight: I18nManager.getConstants().isRTL ? "auto" : "0",
            }}
            onClick={() => setOcrVisible(true)}
          />
        )}
        {orderData?.order_status === "completed" &&
        (!order_review || !order_delivery_review)
          ? orderData?.order_type != "store_pickup" && (
              <>
                {!order_delivery_review && order_review ? (
                  <GButton
                    variant="linkable"
                    children={strings?.w_agent_feedback}
                    type="button"
                    style={{ marginLeft: "auto" }}
                    onClick={() => {
                      setOfvisible(true);
                      setReviewType("delivery");
                    }}
                  />
                ) : (
                  <>
                    {!order_review && (
                      <GButton
                        variant="linkable"
                        children={strings?.w_feedback}
                        style={{ marginLeft: "auto" }}
                        type="button"
                        onClick={() => {
                          setOfvisible(true);
                          setReviewType("order");
                        }}
                      />
                    )}
                  </>
                )}
              </>
            )
          : ""}
      </div>

      <div className="modal-body" style={{ padding: "0 25px" }}>
        {isLoading === true ? (
          <GCardShimmer />
        ) : (
          <React.Fragment>
            {!(
              orderData?.order_status === "completed" ||
              orderData?.order_status === "cancelled"
            ) ? (
              delivery?.id ? (
                // &&
                // delivery?.agent?.id &&
                // (delivery.status == "accepted" ||
                //   delivery.status == "reached" ||
                //   delivery.status == "picked" ||
                //   delivery.status == "arrived")
                <React.Fragment>
                  <OrderTrack
                    deliveryData={delivery}
                    agent={delivery.agent}
                    status={delivery.status_display}
                    setOmvisible={setOmvisible}
                    type="standard"
                    orderStatus={orderData.order_status}
                    routeJsonData={routeJson}
                    showRoute={true}
                    orderData={orderData}
                  />
                  <hr
                    style={{ border: 0, borderBottom: "3px solid #f4f5f9" }}
                  />

                  <GText
                    g5
                    med
                    text={strings?.w_details}
                    style={{
                      textTransform: "uppercase",
                      fontStyle: "italic",
                      margin: "15px 0 10px",
                    }}
                  />
                </React.Fragment>
              ) : (
                ""
              )
            ) : (
              ""
            )}
            <GView
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "10px 0 15px",
                alignItems: "center",
              }}
            >
              <GView style={{ flex: "1" }}>
                <GText g5 semi text={"Created"} style={{ margin: "0px 0" }} />
                <GText
                  g5
                  med
                  text={orderData.created}
                  style={{ margin: "0px 0", fontSize: "13.5px" }}
                />
              </GView>
              <span
                className="orderStatus"
                style={{
                  background: `#${status?.color1}`,
                  color: "#fff",
                  marginLeft: "15px",
                  height: "fit-content",
                  borderRadius: "20px",
                  padding: "3px 12px",
                  fontSize: "14px",
                }}
              >
                {status?.title}
              </span>{" "}
            </GView>

            {orderData?.order_status === "cancelled" && (
              <div
                style={{
                  padding: "10px",
                  border: "1px solid #F96D77",
                  borderRadius: "5px",
                  boxSizing: "border-box",
                  background: "rgba(249, 109, 119, 0.2)",
                }}
              >
                <GText
                  style={{
                    color: "#F96D77",
                    fontWeight: "450",
                    letterSpacing: "0.2px",
                    fontSize: "13.5px",
                  }}
                  text={cancelled_log.reason}
                />
              </div>
            )}

            <hr style={{ border: 0, borderBottom: "3px solid #f4f5f9" }} />
            <div style={{ paddingBottom: "10px" }}>
              <GText
                g6
                med
                text={strings?.w_order_info}
                style={{
                  textTransform: "uppercase",
                  fontStyle: "italic",
                  margin: "15px 0 10px",
                }}
              />
              {orderData?.order_type && (
                <GInlineContent
                  title={strings?.w_order_type}
                  value={
                    orderData.order_type === "store_pickup"
                      ? strings?.w_store_pickup
                      : orderData.order_type === "home_delivery"
                      ? strings?.w_home_delivery
                      : orderData?.order_type?.replace("_", " ")
                  }
                  textTransform="capitalize"
                />
              )}

              {orderData.delivery_type && (
                <GInlineContent
                  title={strings?.w_delivery_type}
                  value={
                    orderData.delivery_type === "asap"
                      ? strings?.w_asap
                      : orderData?.delivery_type === "scheduled"
                      ? strings?.w_scheduled
                      : orderData?.delivery_type
                          .replace("_", " ")
                          .replace(/\b\w/g, (l) => l.toUpperCase()) || "-"
                  }
                  textTransform="capitalize"
                />
              )}

              {orderData.pickup_time && (
                <GInlineContent
                  title={strings?.w_pickup_date}
                  value={orderData.pickup_time}
                />
              )}

              {orderData.delivery_time && (
                <GInlineContent
                  title={strings?.w_delivery_date}
                  value={orderData.delivery_time}
                />
              )}
              {orderData.instructions && (
                <GInlineContent
                  title={strings?.w_instructions}
                  value={orderData.instructions}
                />
              )}
              {orderData?.delivery_otp && (
                <GInlineContent
                  title={strings?.w_delivery_otp}
                  value={orderData.delivery_otp}
                />
              )}
            </div>

            <hr style={{ border: 0, borderBottom: "3px solid #f4f5f9" }} />
            <div>
              <GText
                g6
                med
                text={strings?.w_store_details}
                style={{
                  textTransform: "uppercase",
                  fontStyle: "italic",
                  margin: "15px 0 10px",
                }}
              />

              <div style={{ display: "flex", paddingBottom: "10px" }}>
                <GInfo
                  title={store?.title}
                  subtitle2={store?.address}
                  imgType="store"
                  photo={store?.thumb_photo}
                />
              </div>
            </div>

            <hr style={{ border: 0, borderBottom: "3px solid #f4f5f9" }} />

            {delivery?.delivery_address || orderData.delivery_address ? (
              <div
                style={{
                  paddingBottom: "10px",
                  borderTop: "3px solid #f4f5f9",
                }}
              >
                <GView style={{ display: "flex" }}>
                  <GText
                    g6
                    med
                    text={strings?.w_delivery_address}
                    style={{
                      textTransform: "uppercase",
                      fontStyle: "italic",
                      margin: "15px 0 10px",
                    }}
                  />
                  {["pending", "accepted", "reached"].includes(
                    delivery.status
                  ) && (
                    <GButton
                      variant="linkable"
                      children={strings.w_modify}
                      style={{
                        margin: I18nManager.getConstants().isRTL
                          ? "0 auto 0 0"
                          : "0 0 0 auto",
                      }}
                      onClick={() => setOAdVisible(true)}
                    />
                  )}
                </GView>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <GContent
                    title={strings?.w_home}
                    value={
                      delivery?.delivery_address || orderData.delivery_address
                    }
                    style={{ flex: 1 }}
                  />
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                      delivery?.delivery_address
                        ? "https://www.google.com/maps?q=" +
                          delivery?.delivery_lat +
                          "," +
                          delivery?.delivery_lng
                        : orderData.delivery_address
                        ? "https://www.google.com/maps?q=" +
                          orderData?.delivery_lat +
                          "," +
                          orderData?.delivery_lng
                        : ""
                    }
                  >
                    <NavigateIcon
                      style={{
                        marginLeft: "10px",
                        width: "28px",
                        height: "28px",
                        cursor: "pointer",
                      }}
                    />
                  </a>
                </div>
              </div>
            ) : (
              ""
            )}

            <hr style={{ border: 0, borderBottom: "3px solid #f4f5f9" }} />
            <div style={{ paddingBottom: "10px" }}>
              <GText
                g6
                med
                dir={I18nManager.getConstants().isRTL ? "rtl" : "ltr"}
                text={
                  order_products?.length > 1
                    ? order_products?.length + " " + strings?.w_items
                    : order_products?.length + " " + strings?.w_item
                }
                style={{
                  textTransform: "uppercase",
                  fontStyle: "italic",
                  margin: "15px 0 10px",
                }}
              />
              {order_products && (
                <GItemTable
                  showIng={true}
                  imgTdStyle={{ width: "45px", padding: "4px 5px 4px 0" }}
                  items={order_products}
                  currencySymbol={props.settings.currency_symbol}
                />
              )}
            </div>

            <hr style={{ border: 0, borderBottom: "3px solid #f4f5f9" }} />
            <div>
              <GText
                g6
                med
                text={strings.w_payment_summary}
                style={{
                  textTransform: "uppercase",
                  fontStyle: "italic",
                  margin: "15px 0 10px",
                }}
              />
              {payment_summary?.data.map((p) => {
                return (
                  <GTableContent
                    key={p.title}
                    title={p.title}
                    value={props.settings.currency_symbol + p.value}
                    align="right"
                    lAlign="right"
                    padding="2px 5px"
                  />
                );
              })}
              <hr style={{ border: "0", borderTop: "1px solid #e2e2e2" }} />

              <GTableContent
                title={strings?.w_grand_total}
                value={
                  props.settings.currency_symbol +
                  payment_summary?.total_display
                }
                align="right"
                lAlign="right"
                fontSize="15px"
                color="#000"
                bold={true}
              />
            </div>

            {status.title === "Completed" && (
              <GButton
                variant="condensed"
                children={strings?.w_repeat_order}
                onClick={() => {
                  setCartStore(store?.id);
                  setStoreCart(store?.id);
                  setCart(
                    order_products.map(
                      (o) =>
                        new RepeatOrder({
                          ...o,
                          store_id: o.store_id || store?.id,
                        })
                    )
                  );
                  updateCart(
                    order_products.map(
                      (o) =>
                        new RepeatOrder({
                          ...o,
                          store_id: o.store_id || store?.id,
                        })
                    )
                  );
                  let cartData = order_products.map(
                    (o) =>
                      new RepeatOrder({
                        ...o,
                        store_id: o.store_id || store?.id,
                      })
                  );
                  console.log(JSON.stringify(cartData));
                  localStorage.setItem("cart", JSON.stringify(cartData));
                  setCartvisible(true);
                  props.setOrderInfovisible(false);
                }}
                style={{ width: "100%", margin: "10px 0" }}
              />
            )}
          </React.Fragment>
        )}

        {getIOSVersion() >= 14 && <div style={{ height: "80px" }}></div>}
      </div>

      <Modal
        isOpen={ocrvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setOcrVisible(false)}
        style={{
          content: {
            width: "450px",
          },
        }}
        contentLabel="Order cancel Modal"
      >
        <CancelReasonsView
          ocrvisible={ocrvisible}
          setOcrVisible={setOcrVisible}
          linkedId={orderData.id}
          onSubmit={() => {
            getOrderDetail();
            props.onCancelOrder();
          }}
        />
      </Modal>

      <Modal
        isOpen={ofvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setOfvisible(false)}
        style={{
          content: {
            width: "375px",
          },
        }}
        contentLabel="Order Feedback Modal"
      >
        <OrderFeedback
          reviewType={reviewType}
          ofvisible={ofvisible}
          setOfvisible={setOfvisible}
          orderId={orderData.id}
          onSubmit={getOrderDetail}
          orderData={orderData}
        />
      </Modal>

      <Modal
        isOpen={omvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setOmvisible(false)}
        style={{
          content: {
            width: "450px",
            padding: "20px 0 0",
          },
        }}
        contentLabel="Order message Modal"
      >
        <OrderMessages
          omvisible={omvisible}
          setOmvisible={setOmvisible}
          orderId={orderData.id}
          data={orderMessages}
          onSubmit={(data) => {
            console.log([...orderMessages, data]);
            setOrderMessages([...orderMessages, data]);
          }}
        />
      </Modal>

      <Modal
        isOpen={cartvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setCartvisible(false)}
        style={{
          content: {
            width: "450px",
          },
        }}
        contentLabel="Cart Modal"
      >
        <CartModal
          cartvisible={cartvisible}
          setCartvisible={setCartvisible}
          onProceed={() => {
            navigate(
              {
                pathname: "/checkout",
              },
              {
                state: { title: "Checkout" },
              }
            );
          }}
        />
      </Modal>

      <Modal
        isOpen={oAdVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setOAdVisible(false)}
        style={{
          content: {
            width: "450px",
            padding: "20px 0 0",
          },
        }}
        contentLabel="Modify Order address Modal"
      >
        <ModifyOrderAddress
          oAdVisible={oAdVisible}
          setOAdVisible={setOAdVisible}
          orderId={orderData.id}
          data={delivery}
          onSubmit={(data) => {
            console.log(data);
            setOrderData({ ...data });
          }}
        />
      </Modal>
    </React.Fragment>
  );
}
