import React, { useState, useContext } from "react";
import styled from "styled-components";
import { ImgUrl, addDefaultSrc } from "../ApiService/services";
import GText from "../Elements/GText";
import { ReactComponent as StarRate } from "../assets/images/star.svg";
import { StringsContext } from "../DataFactory/useStrings";
import Modal from "react-modal";
import StoreReviews from "../Components/storeReviews";
import { useNavigate } from "react-router-dom";
import { I18nManager } from "react-native-web";

const StoreBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  box-shadow: -1px -1px 10px #bbbbbb5c;
  padding: 10px;
  .discount {
    background: ${({ theme }) => theme.body};
    color: #fff;
  }
  img {
    object-fit: cover;
    margin-bottom: 15px;
    border-radius: 5px;
    border: 1px solid #f2f2f2;
  }
  .rating {
    margin: 8px 5px;
    display: flex;
    align-items: baseline;
    svg {
      width: 13px;
      fill: ${({ theme }) => theme.body};
    }
  }

  .featured {
    background: #333;
    text-transform: uppercase;
    color: #fff;
    font-size: 12px;
    padding: 5px 10px;
    margin-left: -5px;
    font-weight: 500;
    top: 11px;
    position: absolute;
  }

  .closed {
    background: rgba(0, 0, 0, 0.6);
    font-size: 18px;
    text-transform: uppercase;
    color: #fff;
    position: absolute;
    top: 1px;
    left: 1px;
    text-align: center;
    padding-top: 75px;
    margin: 10px;
    width: calc(100% - 20px);
    z-index: 1;
    height: calc(170px - 75px);
    font-weight: 600;
  }
`;

export default function GStoreView(props) {
  let navigate = useNavigate();
  const [strings] = useContext(StringsContext);
  const [storeData] = useState(props.data);
  const [srvisible, setSRVisible] = useState(false);

  const goToCategories = () => {
    navigate(
      {
        pathname: `/store/${props.data?.id}/products`,
      },
      {
        state: {
          store: props.data?.id,
          title: "Products",
        },
      }
    );
  };

  return (
    <React.Fragment>
      <StoreBox
        style={Object.assign(
          {
            cursor: props.data.store_status === 0 ? "auto" : "pointer",
            flexDirection: props.theme === "row" ? "row" : "column",
          },
          props.mainDivStyle
        )}
      >
        <img
          src={ImgUrl("store") + "/" + props.data.photo}
          alt={props.data.title}
          onError={(e) => addDefaultSrc(e, "store")}
          onClick={() => {
            if (storeData.store_status === 0) {
              return;
            }
            goToCategories();
          }}
          width="auto"
          height={props.theme === "row" ? "100px" : "170px"}
          style={{
            height: props.theme === "row" ? "100px" : "170px",
            maxWidth: props.theme === "row" ? "100px" : "100%",
          }}
        />
        {props.data.featured === 1 ? <div className="featured">✦</div> : ""}

        <div
          style={{
            margin:
              props.theme === "row"
                ? I18nManager.getConstants().isRTL
                  ? "0 10px 0 0"
                  : "0 0 0 10px"
                : 0,
            width: props.theme === "row" ? "calc(100% - 115px)" : "100%",
            minHeight: props.theme === "row" ? "auto" : "150px",
          }}
        >
          <div
            className="info"
            onClick={() => {
              if (storeData.store_status === 0) {
                return;
              }
              goToCategories();
            }}
          >
            <GText
              g3
              semi
              text={props.data.title}
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                padding: "0 5px",
              }}
            />
            {props.data.promotion_display ? (
              <GText
                semi
                className="discount"
                text={`${props.data.promotion_display}`}
                style={{
                  padding: "2px 7px",
                  margin: "3px 5px",
                  width: "auto",
                  fontSize: "14px",
                  display: "inline-block",
                }}
              />
            ) : (
              ""
            )}
            <GText
              med
              text={props.data.address}
              style={{
                margin: "5px 0",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                padding: "0 5px",
              }}
            />

            <GText
              light
              text={`Delivery Cost : ${props.data.delivery_fee_display} • ${props.data.delivery_time_display}`}
              style={{
                margin: "5px 0",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                padding: "0 5px",
              }}
            />
          </div>

          <p className="rating" onClick={() => setSRVisible(true)}>
            <StarRate /> &nbsp;&nbsp;{props.data.rating_display?.display}
          </p>
        </div>
        {props.data.store_status === 0 ? (
          <div className="closed">{strings?.w_closed}</div>
        ) : (
          ""
        )}
      </StoreBox>

      <Modal
        isOpen={srvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setSRVisible(false)}
        style={{
          content: {
            width: "400px",
            padding: "20px 0",
            overflow: "hidden",
          },
        }}
        contentLabel="Store Review Modal"
      >
        <StoreReviews
          srvisible={srvisible}
          setSRVisible={setSRVisible}
          store={storeData}
        />
      </Modal>
    </React.Fragment>
  );
}
