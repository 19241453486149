import React, { useState, useEffect, useContext } from "react";
import { SettingsContext } from "../DataFactory/useSettings";
import { CartContext } from "../DataFactory/useCart";
import useCart from "../useCart";
import useWindowSize from "../DataFactory/useWindowSize";
import { StringsContext } from "../DataFactory/useStrings";
import { UserLocContext } from "../contexts/userLocation";
import { ApiLoginService, ApiService } from "../ApiService/services";
import StoreInfo from "./storeInfoView";
import GView from "../Elements/GView";
import GButton from "../Elements/GButton";
import Modal from "react-modal";
import SignIn from "../forms/login";
import SignUp from "../forms/signup";
import ForgotPassword from "../forms/forgotPassword";
import StoreBranchesList from "../Components/storeBranches";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import CategoriesView from "./categoriesView";
import ProductsView from "./productsView";
import Cart from "./cartView";
import GProductsSlider from "../Elements/gProductsSliderView";
import GText from "../Elements/GText";
import styled from "styled-components";
import GroceryProducts from "../poductsGroceryTheme/categories";
import GroceryProductsView from "../poductsGroceryTheme/productsView";

const customStyles = {
  content: {
    width: "400px",
  },
};

const HR = styled.hr`
  border-color: ${({ theme }) => theme.body};
  width: 40px;
  margin: 0 5px 10px;
`;

export default function Products() {
  let { storeId } = useParams();
  let navigate = useNavigate();
  const { cart } = useCart();
  const { width } = useWindowSize();
  const { itemCart } = useContext(CartContext);
  const [strings] = useContext(StringsContext);
  const { settings } = useContext(SettingsContext);
  const { userLocation } = useContext(UserLocContext);
  const [signinvisible, setSigninvisible] = useState(false);
  const [fpvisible, setFpvisible] = useState(false);
  const [signupvisible, setSignupvisible] = useState(false);
  const [cMenuVisible, setCMenuVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [storeData, setStoreData] = useState({});
  const [categoryData, setCategoryData] = useState([]);
  const [offerProductsData, setOfferProductsData] = useState([]);
  const [mostSellingProductsData, setMostSellingProductsData] = useState([]);
  const [sBranchVisible, setSBranchVisible] = useState(false);
  const [branchesData, setBranchesData] = useState([]);
  const [selectedCatId, setSelectedCatId] = useState("");

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    if (settings?.user?.id) {
      ApiService({
        method: "GET",
        route: "c/stores/" + storeId,
        body: { geofence: "", location: userLocation || "" },
      })
        .then((response) => {
          console.log(response.data);
          if (response.data.status_code === 1) {
            setStoreData(response.data.data || {});
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      ApiLoginService("GET", "c/stores/" + storeId)
        .then((response) => {
          console.log(response);
          if (response.status_code === 1) {
            setStoreData(response.data || {});
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  const getCategories = () => {
    ApiLoginService("GET", "c/product/group", {
      include_products: true,
      store_id: storeId,
      location: userLocation || "",
    })
      .then((response) => {
        if (response.status_code === 1) {
          setCategoryData(response.data);
          setSelectedCatId(response.data[0]?.id || "");
          setOfferProductsData(response.offer_products || []);
          setMostSellingProductsData(response.most_selling || []);
          setBranchesData(response?.branches || []);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function myFunction() {
    var footer = document.getElementById("stickyBtn");
    var footerBtn = document.getElementById("browseMenuBtn");
    var sticky = footer?.offsetTop - 300;
    if (sticky && footerBtn) {
      if (window.pageYOffset > sticky) {
        footerBtn.classList.add("hide");
      } else {
        footerBtn.classList.remove("hide");
      }
    }
  }

  useEffect(() => {
    if (width <= 959) {
      window.addEventListener("scroll", myFunction);
    }
  }, []);

  const goToCheckout = () => {
    if (settings?.user?.id) {
      navigate({ pathname: "/checkout" }, { state: { title: "Checkout" } });
    } else {
      setSigninvisible(true);
    }
  };

  // useEffect(() => {
  //   if (selectedCatId && storeData?.theme == "grocery") {
  //     if (searchParams.get("category_id") != selectedCatId) {
  //       navigate(
  //         {
  //           pathname: "/store/" + storeId + "/products",
  //         },
  //         {
  //           state: { title: "Products" },
  //           search: "?category_id=" + selectedCatId,
  //         }
  //       );
  //     }
  //   }
  // }, [selectedCatId]);

  return (
    <div
      className="main-content"
      style={{ paddingTop: "15px", background: "#fff" }}
    >
      {/* <div style={{ position: "relative", textAlign: "right" }}>
        <SearchComponent storeId={storeData?.id} />
      </div> */}

      <StoreInfo
        data={storeData}
        settings={settings}
        onAddToFavourite={() => setSigninvisible(true)}
        branchesData={branchesData}
        onClick={() => setSBranchVisible(true)}
      />

      {offerProductsData && offerProductsData.length > 0 && (
        <GView style={{ marginBottom: "15px" }}>
          <GText
            g4
            semi
            text={strings.w_offers}
            style={{ margin: "5px 5px 0 5px" }}
          />
          <HR />
          <GProductsSlider
            data={offerProductsData || []}
            isOfferView={true}
            cardStyle={{
              boxShadow: "rgba(204, 204, 204, 0.52) 0px 0px 10px",
              margin: "5px 7px",
            }}
          />
        </GView>
      )}

      {mostSellingProductsData && mostSellingProductsData.length > 0 && (
        <GView style={{ margin: "30px 0 15px" }}>
          <GText
            g4
            semi
            text={strings.w_most_selling}
            style={{ margin: "5px 5px 0 5px" }}
          />
          <HR />
          <GProductsSlider
            data={mostSellingProductsData || []}
            isOfferView={true}
            cardStyle={{
              boxShadow: "rgba(204, 204, 204, 0.52) 0px 0px 10px",
              margin: "5px 7px",
            }}
          />
        </GView>
      )}

      {storeData?.theme === "grocery" ? (
        <>
          {categoryData && categoryData.length > 0 && (
            <div id="gProductView" style={{ paddingBottom: "20px" }}>
              <div
                style={{
                  borderBottom: "2px solid rgb(221, 221, 221)",
                  marginBottom: "15px",
                  padding: "20px 0px 0px",
                }}
              >
                <GroceryProducts
                  storeId={storeData?.id}
                  settings={settings}
                  isLoading={isLoading}
                  onCatSelect={(catId) => setSelectedCatId(catId)}
                  data={categoryData}
                  selectedCatId={selectedCatId}
                />
              </div>

              <div
                style={{
                  position: "relative",
                  display: "inline-block",
                  verticalAlign: "top",
                  width:
                    width > 959 && categoryData.length > 1 ? "75%" : "100%",
                  minHeight: width > 959 ? "calc(100vh - 100px)" : "auto",
                }}
              >
                <GroceryProductsView
                  storeId={storeData?.id}
                  selectedCatId={selectedCatId}
                  data={categoryData}
                />
              </div>
              <div
                style={{
                  display: "inline-block",
                  verticalAlign: "top",
                  width: width > 959 ? "25%" : "100%",
                }}
              >
                <Cart settings={settings} goToCheckout={goToCheckout} />
              </div>
            </div>
          )}
        </>
      ) : (
        <div
          style={{ flex: "1", flexDirection: "row", background: "#fff" }}
          id="productView"
        >
          {width > 959 && (
            <>
              <CategoriesView
                isLoading={isLoading}
                storeId={storeId}
                onSubCatSelect={(catId) => setSelectedCatId(catId)}
                data={categoryData}
              />

              <div
                style={{
                  position: "relative",
                  display: "inline-block",
                  verticalAlign: "top",
                  width: "50%",
                }}
              >
                <ProductsView
                  isLoading={isLoading}
                  storeId={storeId}
                  selectedCatId={selectedCatId}
                  data={categoryData}
                />
              </div>

              <div
                style={{
                  display: "inline-block",
                  verticalAlign: "top",
                  width: "25%",
                }}
              >
                <Cart settings={settings} goToCheckout={goToCheckout} />
              </div>
            </>
          )}

          {width <= 959 && (
            <div
              style={{
                position: "relative",
                display: "inline-block",
                verticalAlign: "top",
                width: "100%",
              }}
            >
              <GButton
                id="browseMenuBtn"
                variant="condensed"
                onClick={() => setCMenuVisible(true)}
                style={{
                  borderRadius: "20px",
                  textTransform: "uppercase",
                  position: "fixed",
                  bottom: "100px",
                  width: "200px",
                  zIndex: "1",
                  padding: "0.6rem",
                  margin: "0 calc(45% - 100px)",
                }}
              >
                {strings?.w_browse_menu}
              </GButton>

              <ProductsView
                isLoading={isLoading}
                storeId={storeId}
                selectedCatId={selectedCatId}
                data={categoryData}
              />

              <Cart
                items={itemCart}
                settings={settings}
                goToCheckout={goToCheckout}
              />
            </div>
          )}
        </div>
      )}

      <Modal
        isOpen={cMenuVisible}
        className="modal"
        overlayClassName="modal-overlay"
        onRequestClose={() => setCMenuVisible(false)}
        style={{
          content: {
            width: "300px",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            height: "60vh",
            borderRadius: "10PX",
          },
        }}
        contentLabel="Browse Menu Modal"
      >
        <CategoriesView
          isModal={true}
          data={categoryData}
          storeId={storeId}
          onSubCatSelect={(catId) => {
            setSelectedCatId(catId);
            setCMenuVisible(false);
          }}
        />
      </Modal>

      <Modal
        isOpen={signinvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setSigninvisible(false)}
        style={customStyles}
        contentLabel="Signin Form Modal"
      >
        <SignIn
          signinvisible={signinvisible}
          setSigninvisible={setSigninvisible}
          setSignupvisible={setSignupvisible}
          setFpvisible={setFpvisible}
          settings={settings}
        />
      </Modal>

      <Modal
        isOpen={signupvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setSignupvisible(false)}
        style={customStyles}
        contentLabel="Signup Form Modal"
      >
        <SignUp
          signupvisible={signupvisible}
          setSignupvisible={setSignupvisible}
          setSigninvisible={setSigninvisible}
          settings={settings}
        />
      </Modal>

      <Modal
        isOpen={fpvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setFpvisible(false)}
        style={customStyles}
        contentLabel="Forgot Password Modal"
      >
        <ForgotPassword
          fpvisible={fpvisible}
          setFpvisible={setFpvisible}
          setSigninvisible={setSigninvisible}
          settings={settings}
        />
      </Modal>

      <Modal
        isOpen={sBranchVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setSBranchVisible(false)}
        style={customStyles}
        contentLabel="branches Modal"
      >
        <StoreBranchesList
          sBranchVisible={sBranchVisible}
          setSBranchVisible={setSBranchVisible}
          settings={settings}
          data={branchesData}
        />
      </Modal>
    </div>
  );
}
