import React, { useState, useEffect, useContext } from "react";
import { ApiService } from "../ApiService/services";
import GBackIcon from "../Elements/gBackIcon";
import { useEnqueueSnackbar } from "../DataFactory/useCustomSanckbar";
import GButton from "../Elements/GButton";
import { GCardShimmer } from "../Elements/gShimmer";
import { GContent, GTableContent } from "../Elements/gContent";
import Modal from "react-modal";
import CancelReasonsView from "../Components/orderCancelReasons";
import OrderFeedback from "../Components/orderFeedback";
import GText from "../Elements/GText";
import GView from "../Elements/GView";
import { ReactComponent as NavigateIcon } from "../assets/images/navigation.svg";
import { StringsContext } from "../DataFactory/useStrings";
import { I18nManager } from "react-native";

function getIOSVersion() {
  var match = window.navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
  return match ? match[1] : undefined;
}

export default function AtmOrderDetail(props) {
  const [strings] = useContext(StringsContext);
  const [orderData, setOrderData] = useState({});
  const [ocrvisible, setOcrVisible] = useState(false);
  const [ofvisible, setOfvisible] = useState(false);
  const [reviewType, setReviewType] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const {
    status,
    delivery,
    payment_summary,
    order_review,
    order_delivery_review,
    cancelled_log,
  } = orderData;
  const enqueueSnackbar = useEnqueueSnackbar();

  const getOrderDetail = () => {
    ApiService({ method: "GET", route: "c/orders/" + props.linkedId })
      .then((response) => {
        console.log(response);
        if (response.status === 26) {
          enqueueSnackbar(
            strings?.s_placeholder_internet_title +
              ". " +
              strings?.s_placeholder_internet_subtitle
          );
          return;
        } else {
          if (response?.data?.status_code === 1) {
            setOrderData(response.data.data);
            setIsLoading(false);
          } else {
            enqueueSnackbar(response?.data?.message);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getOrderDetail();
  }, [props.linkedId]);

  if (props.atmOrderInfovisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="modal-header">
        <GBackIcon onClick={() => props.setAtmOrderInfovisible(false)} />{" "}
        {strings?.w_order_details}
        {orderData?.order_status === "pending" && (
          <GButton
            variant="linkable"
            children={strings?.w_problem}
            type="button"
            style={{
              marginLeft: I18nManager.getConstants().isRTL ? "0" : "auto",
              marginRight: I18nManager.getConstants().isRTL ? "auto" : "0",
            }}
            onClick={() => setOcrVisible(true)}
          />
        )}
        {orderData?.order_status === "completed" &&
        (!order_review || !order_delivery_review)
          ? orderData?.order_type !== "store_pickup" && (
              <>
                {!order_delivery_review && order_review ? (
                  <GButton
                    variant="linkable"
                    children={strings?.w_agent_feedback}
                    type="button"
                    style={{ marginLeft: "auto" }}
                    onClick={() => {
                      setOfvisible(true);
                      setReviewType("delivery");
                    }}
                  />
                ) : (
                  <>
                    {!order_review && (
                      <GButton
                        variant="linkable"
                        children={strings?.w_feedback}
                        style={{ marginLeft: "auto" }}
                        type="button"
                        onClick={() => {
                          setOfvisible(true);
                          setReviewType("order");
                        }}
                      />
                    )}
                  </>
                )}
              </>
            )
          : ""}
      </div>

      <div className="modal-body" style={{ padding: "0 25px" }}>
        {isLoading === true ? (
          <GCardShimmer />
        ) : (
          <React.Fragment>
            <GView
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "10px 0 15px",
                alignItems: "center",
              }}
            >
              <GView style={{ flex: "1" }}>
                <GText g5 semi text={"Created"} style={{ margin: "0px 0" }} />
                <GText
                  g5
                  med
                  text={orderData.created}
                  style={{ margin: "0px 0", fontSize: "13.5px" }}
                />
              </GView>
              <span
                className="orderStatus"
                style={{
                  background: `#${status?.color1}`,
                  color: "#fff",
                  marginLeft: "15px",
                  height: "fit-content",
                  borderRadius: "20px",
                  padding: "3px 12px",
                  fontSize: "14px",
                }}
              >
                {status?.title}
              </span>{" "}
            </GView>

            {orderData?.order_status === "cancelled" && (
              <div
                style={{
                  padding: "10px",
                  border: "1px solid #F96D77",
                  borderRadius: "5px",
                  boxSizing: "border-box",
                  background: "rgba(249, 109, 119, 0.2)",
                }}
              >
                <GText
                  style={{
                    color: "#F96D77",
                    fontWeight: "450",
                    letterSpacing: "0.2px",
                    fontSize: "13.5px",
                  }}
                  text={cancelled_log.reason}
                />
              </div>
            )}
            <hr style={{ border: 0, borderBottom: "3px solid #f4f5f9" }} />

            {delivery?.delivery_address || orderData.delivery_address ? (
              <div
                style={{
                  paddingBottom: "10px",
                  borderTop: "3px solid #f4f5f9",
                }}
              >
                <GText
                  g6
                  med
                  text={strings?.w_delivery_address}
                  style={{
                    textTransform: "uppercase",
                    fontStyle: "italic",
                    margin: "15px 0 10px",
                  }}
                />
                <div style={{ display: "flex", alignItems: "center" }}>
                  <GContent
                    title={""}
                    value={
                      delivery?.delivery_address || orderData.delivery_address
                    }
                    style={{ flex: 1 }}
                  />
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                      delivery?.delivery_address
                        ? "https://www.google.com/maps?q=" +
                          delivery?.delivery_lat +
                          "," +
                          delivery?.delivery_lng
                        : orderData.delivery_address
                        ? "https://www.google.com/maps?q=" +
                          orderData?.delivery_lat +
                          "," +
                          orderData?.delivery_lng
                        : ""
                    }
                  >
                    <NavigateIcon
                      style={{
                        marginLeft: "10px",
                        width: "28px",
                        height: "28px",
                        cursor: "pointer",
                      }}
                    />
                  </a>
                </div>
              </div>
            ) : (
              ""
            )}

            <hr style={{ border: 0, borderBottom: "3px solid #f4f5f9" }} />
            <div>
              <GText
                g6
                med
                text={strings.w_payment_summary}
                style={{
                  textTransform: "uppercase",
                  fontStyle: "italic",
                  margin: "15px 0 10px",
                }}
              />
              {payment_summary?.data.map((p) => {
                return (
                  <GTableContent
                    key={p.title}
                    title={p.title}
                    value={props.settings.currency_symbol + p.value}
                    align="right"
                    lAlign="right"
                    padding="2px 5px"
                  />
                );
              })}
              <hr style={{ border: "0", borderTop: "1px solid #e2e2e2" }} />

              <GTableContent
                title={strings?.w_grand_total}
                value={
                  props.settings.currency_symbol +
                  payment_summary?.total_display
                }
                align="right"
                lAlign="right"
                fontSize="15px"
                color="#000"
                bold={true}
              />
            </div>
          </React.Fragment>
        )}

        {getIOSVersion() >= 14 && <div style={{ height: "80px" }}></div>}
      </div>

      <Modal
        isOpen={ocrvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setOcrVisible(false)}
        style={{
          content: {
            width: "450px",
          },
        }}
        contentLabel="Order cancel Modal"
      >
        <CancelReasonsView
          ocrvisible={ocrvisible}
          setOcrVisible={setOcrVisible}
          linkedId={orderData.id}
          onSubmit={() => {
            getOrderDetail();
            props.onCancelOrder();
          }}
        />
      </Modal>

      <Modal
        isOpen={ofvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setOfvisible(false)}
        style={{
          content: {
            width: "375px",
          },
        }}
        contentLabel="Order Feedback Modal"
      >
        <OrderFeedback
          reviewType={reviewType}
          ofvisible={ofvisible}
          setOfvisible={setOfvisible}
          orderId={orderData.id}
          onSubmit={() => getOrderDetail()}
        />
      </Modal>
    </React.Fragment>
  );
}
