import React, { useState, useContext, useEffect } from "react";
import GView from "../Elements/GView";
import GBackIcon from "../Elements/gBackIcon";
import { StringsContext } from "../DataFactory/useStrings";
import { GCardShimmer } from "../Elements/gShimmer";
import GText from "../Elements/GText";
import { useNavigate } from "react-router-dom";

export default function StoreBranches(props) {
  let navigate = useNavigate();
  const [strings] = useContext(StringsContext);
  const [isIntialLoading, setIsInitialLoading] = useState(true);
  const [branchesData, setBranchesData] = useState(props.data || []);

  useEffect(() => {
    setBranchesData(props.data || []);
    setIsInitialLoading(false);
  }, [props]);

  if (props.couponVisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="modal-header">
        <GBackIcon onClick={() => props.setCouponVisible(false)} />
        {strings?.w_choose_branch}
      </div>
      <div className="modal-body">
        {isIntialLoading === true ? (
          <GCardShimmer type="single" />
        ) : (
          <ul style={{ margin: 0, paddingLeft: "30px" }}>
            {branchesData.map((branch, ind) => {
              return (
                <li
                  key={ind}
                  style={{
                    borderBottom: "1px solid #e2e2e2",
                    padding: "12px 10px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate(
                      {
                        pathname: `/store/${branch?.id}/products`,
                      },
                      {
                        state: {
                          store: branch?.id,
                          title: "Products",
                        },
                      }
                    );
                  }}
                >
                  <GText med text={branch?.address} />
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </React.Fragment>
  );
}
