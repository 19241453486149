import React, { useState, useEffect, useContext } from "react";
import { FlatList, View, Platform } from "react-native";
import { SettingsContext } from "../DataFactory/useSettings";
import OrderInfo from "./orderDetail";
import CourierOrderDetail from "./orderDetailCourier";
import AtmOrderDetail from "./orderDetailAtm";
import useDataFctory from "../ApiService/useDataFactory";
import GButton from "../Elements/GButton";
import Modal from "react-modal";
import GInfo from "../Elements/gInfo";
import GText from "../Elements/GText";
import GView from "../Elements/GView";
import useWindowSize from "../DataFactory/useWindowSize";
import { StringsContext } from "../DataFactory/useStrings";
import styled from "styled-components";

const StatusFilterView = styled.div`
  display: flex;
  p {
    border: 1px solid #aaa;
    padding: 5px 12px;
    font-size: 14px;
    color: #777;
    cursor: pointer;

    &.active {
      border: 1px solid ${({ theme }) => theme.body};
      background: ${({ theme }) => theme.opacityColor};
      color: ${({ theme }) => theme.body};
      font-weight: 600;
    }
  }
`;

export default function Orders(props) {
  const { width } = useWindowSize();
  const { settings } = useContext(SettingsContext);
  const [strings] = useContext(StringsContext);
  const [orderInfovisible, setOrderInfovisible] = useState(false);
  const [couOrderInfovisible, setCouOrderInfovisible] = useState(false);
  const [atmOrderInfovisible, setAtmOrderInfovisible] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(
    props.location?.state?.order_id || ""
  );
  const [selectedOrdersStatus, setSelectedOrderStatus] = useState("active");
  const {
    loading,
    Shimmer,
    Placeholder,
    data,
    loadMore,
    pagination,
    refreshData,
  } = useDataFctory("order", true, {
    order_status: selectedOrdersStatus,
  });

  useEffect(() => {
    setBtnDisabled(false);
  }, [data]);

  useEffect(() => {
    if (props.location?.state?.order_id) {
      setSelectedOrderId(props.location?.state?.order_id);
      setTimeout(function () {
        // setOrderInfovisible(true);
      }, 1000);
    }
  }, []);

  useEffect(() => {
    refreshData({ order_status: selectedOrdersStatus });
  }, [selectedOrdersStatus]);

  let count = Math.round(width / (Platform.OS === "web" ? 600 : 300));

  return (
    <React.Fragment>
      <GView
        style={{
          display: "flex",
          flexDirection: width > 767 ? "row" : "column",
          alignItems: width > 767 ? "center" : "left",
        }}
      >
        <GView
          style={{ display: "flex", margin: "10px 5px", alignItems: "center" }}
        >
          <GText g2="true" theme bold text={strings?.w_orders} />
          {pagination?.total > 0 && (
            <>
              &nbsp;
              <GText
                g5
                med
                semi
                text={
                  pagination.total > 1
                    ? ` (${pagination?.total} ${strings?.w_orders})`
                    : ` (${pagination?.total} ${strings?.w_order})`
                }
                style={{ fontStyle: "italic" }}
              />
            </>
          )}
        </GView>
        <GView style={{ margin: width > 767 ? "0 0 0 auto" : "10px auto" }}>
          <StatusFilterView>
            <GText
              text={strings.w_active_orders}
              className={selectedOrdersStatus === "active" ? "active" : ""}
              style={{
                borderTopLeftRadius: "4px",
                borderBottomLeftRadius: "4px",
                borderRightWidth: selectedOrdersStatus === "active" ? "1px" : 0,
              }}
              onClick={() => setSelectedOrderStatus("active")}
            />
            <GText
              text={strings.w_past_orders}
              className={selectedOrdersStatus !== "active" ? "active" : ""}
              style={{
                borderTopRightRadius: "4px",
                borderBottomRightRadius: "4px",
                borderLeftWidth: selectedOrdersStatus !== "active" ? "1px" : 0,
              }}
              onClick={() => setSelectedOrderStatus("inactive")}
            />
          </StatusFilterView>
        </GView>
      </GView>
      {loading === true ? (
        <Shimmer></Shimmer>
      ) : (
        <React.Fragment>
          <View style={{ flex: 1, flexDirection: "row" }}>
            <FlatList
              key={
                width <= 767
                  ? "s"
                  : width > 767 && width < 992
                  ? "m"
                  : width >= 992 && width < 1500
                  ? "l"
                  : "xl"
              }
              initialNumToRender={data.length}
              contentContainerStyle={{ flex: 1 }}
              keyExtractor={(data) => data.id}
              data={data}
              numColumns={count}
              renderItem={({ item }) => (
                <div style={{ flex: 1 / count }}>
                  <div
                    onClick={() => {
                      setSelectedOrderId(item?.id);
                      if (item.class === "courier") {
                        setCouOrderInfovisible(true);
                      } else if (item.class === "atm") {
                        setAtmOrderInfovisible(true);
                      } else {
                        setOrderInfovisible(true);
                      }
                    }}
                    style={{
                      background: "#fff",
                      padding: "12px 12px 20px",
                      margin: "8px",
                      cursor: "pointer",
                      border: "1px solid #f2f2f2",
                      height: "calc(100% - 54px)",
                    }}
                  >
                    <GInfo
                      // title={`#${item?.id}`}
                      rightTitle={item.class === "atm" ? strings.w_cash : ""}
                      subtitle={
                        item.class === "courier"
                          ? item?.pickup?.delivery_address
                          : item.class === "atm"
                          ? item.delivery_address
                          : item?.store?.title
                      }
                      subtitle2={item?.created}
                      photo={
                        item.class === "courier" || item.class === "atm"
                          ? item?.agent?.thumb_photo
                          : item?.store?.thumb_photo
                      }
                      imgType={
                        item.class === "courier" || item.class === "atm"
                          ? "user"
                          : "store"
                      }
                      imgOrderType={
                        item.class === "courier" || item.class === "atm"
                          ? item.class
                          : ""
                      }
                      status={item?.status}
                      amount={settings.currency_symbol + item?.amount_display}
                      titleTheme={true}
                      titleStyle={{
                        fontSize: "14px",
                      }}
                      subtitleStyle={{
                        fontSize:
                          item.class === "courier" || item.class === "atm"
                            ? "14px"
                            : "16px",
                        lineHeight:
                          item.class === "courier" || item.class === "atm"
                            ? "20px"
                            : "21px",
                        fontWeight: "500",
                      }}
                      imgStyle={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "5px",
                      }}
                      rightTitleStyles={{
                        borderRadius: "4px",
                        padding: "2px 10px",
                        float: "none",
                        width: "max-content",
                        marginBottom: 5,
                      }}
                      // style={{ flexFlow: "row-reverse" }}
                    />
                  </div>
                </div>
              )}
              ListEmptyComponent={Placeholder}
            />
          </View>

          {pagination.next_page_url && (
            <GButton
              loading={btnDisabled}
              variant="condensed"
              children={strings?.w_load_more}
              type="button"
              onClick={() => {
                setBtnDisabled(true);
                loadMore();
              }}
              style={{
                margin: "10px auto",
                width: "150px",
                justifyContent: "center",
                display: "flex",
              }}
            />
          )}
        </React.Fragment>
      )}
      <Modal
        isOpen={orderInfovisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => {
          setOrderInfovisible(false);
        }}
        style={{
          content: {
            width: "450px",
            padding: "20px 0px",
            overflow: "hidden",
          },
        }}
        contentLabel="Order Detail Modal"
      >
        <OrderInfo
          orderInfovisible={orderInfovisible}
          setOrderInfovisible={setOrderInfovisible}
          linkedId={selectedOrderId}
          settings={settings}
          onStatusUpdate={() => refreshData()}
          onCancelOrder={() => refreshData()}
        />
      </Modal>

      <Modal
        isOpen={couOrderInfovisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => {
          setCouOrderInfovisible(false);
        }}
        style={{
          content: {
            width: "450px",
            padding: "20px 0px",
            overflow: "hidden",
          },
        }}
        contentLabel="Courier Order Detail Modal"
      >
        <CourierOrderDetail
          couOrderInfovisible={couOrderInfovisible}
          setCouOrderInfovisible={setCouOrderInfovisible}
          linkedId={selectedOrderId}
          settings={settings}
          onStatusUpdate={() => refreshData()}
          onCancelOrder={() => refreshData()}
        />
      </Modal>

      <Modal
        isOpen={atmOrderInfovisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => {
          setAtmOrderInfovisible(false);
        }}
        style={{
          content: {
            width: "450px",
            padding: "20px 0px",
            overflow: "hidden",
          },
        }}
        contentLabel="ATM Order Detail Modal"
      >
        <AtmOrderDetail
          atmOrderInfovisible={atmOrderInfovisible}
          setAtmOrderInfovisible={setAtmOrderInfovisible}
          linkedId={selectedOrderId}
          settings={settings}
          onStatusUpdate={() => refreshData()}
          onCancelOrder={() => refreshData()}
        />
      </Modal>
    </React.Fragment>
  );
}
