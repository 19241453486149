import React, { useState, useEffect, useContext } from "react";
import { ImgUrl, addDefaultSrc } from "../ApiService/services";
import GText from "../Elements/GText";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SettingsContext } from "../DataFactory/useSettings";
import { StringsContext } from "../DataFactory/useStrings";
import "../Css/slickCarousel.css";
import styled from "styled-components";
import { Item } from "../Models/Item";
import { I18nManager } from "react-native";
import useWindowSize from "../DataFactory/useWindowSize";
import Modal from "react-modal";
import ProductInfo from "../Components/productInfo";
import GView from "./GView";

const ProductDiv = styled.div`
  background: #fff;
  border: 1px solid rgb(242, 242, 242);
  cursor: pointer;
  border-radius: 8px;
  margin: 0 10px 0 0;
  position: relative;
  p {
    font-size: 14px;
    .price {
      color: ${({ theme }) => theme.body};
    }
  }

  .discount {
    color: #fff;
    background: ${({ theme }) => theme.body};
    position: absolute;
    top: 20px;
    right: 0;
    padding: 2px 8px;
    font-size: 14px;
    box-shadow: rgba(204, 204, 204, 0.52) 0px 0px 10px;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }
`;

const getIndexImage = (index) => {
  if (index == 0) {
    return require("../assets/images/numbers/1.png");
  } else if (index == 1) {
    return require("../assets/images/numbers/2.png");
  } else if (index == 2) {
    return require("../assets/images/numbers/3.png");
  } else if (index == 3) {
    return require("../assets/images/numbers/4.png");
  } else if (index == 4) {
    return require("../assets/images/numbers/5.png");
  } else if (index == 5) {
    return require("../assets/images/numbers/6.png");
  } else if (index == 6) {
    return require("../assets/images/numbers/7.png");
  } else if (index == 7) {
    return require("../assets/images/numbers/8.png");
  } else if (index == 8) {
    return require("../assets/images/numbers/9.png");
  } else if (index == 9) {
    return require("../assets/images/numbers/10.png");
  }
  return require("../assets/images/numbers/10.png");
};

export default function GProductsSlider(props) {
  const [products, setProducts] = useState(props.data || []);
  const [itemInfoVisible, setItemInfoVisible] = useState(false);
  const { settings } = useContext(SettingsContext);
  const [strings] = useContext(StringsContext);
  const [display, setDisplay] = useState(true);
  const { width } = useWindowSize();
  const [selectedProduct, setSelectedProduct] = useState({});

  var SliderSettings = {
    dots: false,
    infinite: false,
    swipe: false,
    speed: 500,
    slidesToShow:
      props.slidesShow ||
      (width >= 1800
        ? 7
        : width > 1500 && width <= 1799
        ? 6
        : width > 1279 && width <= 1500
        ? 4.7
        : width > 991 && width <= 1279
        ? 4
        : width > 767 && width <= 991
        ? 3
        : width > 500 && width <= 767
        ? 2
        : 1.7),
    slidesToScroll: 1,
  };

  useEffect(() => {
    console.log(props.data.map((o) => new Item(o)));
    setProducts(props.data.map((o) => new Item(o)));
  }, [props]);

  return (
    <div>
      <div
        style={{
          width: "100%",
          display: display ? "block" : "none",
        }}
      >
        <Slider {...SliderSettings}>
          {products.map((product, index) => {
            return (
              <div key={index}>
                <ProductDiv
                  style={Object.assign(
                    {
                      padding: "5px",
                      margin: "0px 7px",
                    },
                    props.cardStyle
                  )}
                  onDoubleClick={() => {
                    setSelectedProduct(product);
                    setItemInfoVisible(true);
                  }}
                >
                  <div
                    style={{
                      aspectRatio: "5/3",
                      position: "relative",
                    }}
                    onClick={() => {
                      setSelectedProduct(product);
                      setItemInfoVisible(true);
                    }}
                  >
                    <img
                      src={ImgUrl("item") + "/" + product.photo}
                      alt={product.title}
                      onError={(e) => addDefaultSrc(e, "item")}
                      style={Object.assign(
                        {
                          maxWidth: "100%",
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        },
                        props.imgStyle
                      )}
                    />

                    {props.isOfferView == true && (
                      <div className="discount">{product.discount}% off</div>
                    )}

                    {props.showGroupIndex == true && (
                      <img
                        src={getIndexImage(index)}
                        style={{
                          position: "absolute",
                          bottom: 0,
                          width: width < 767 ? "65px" : "75px",
                        }}
                      />
                    )}
                  </div>

                  {props.isOfferView == true ? (
                    <GView style={{ paddingBottom: "5px" }}>
                      <GText
                        style={{
                          margin: "10px 0 2px",
                          color: "#333",
                          fontSize: "15px",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          textWrap: "nowrap",
                        }}
                        text={product.title}
                      />
                      {product.price != product.price_old ? (
                        <GView>
                          <GText
                            theme
                            semi
                            text={
                              settings?.currency_symbol +
                              product.price_discounted
                            }
                            style={{
                              display: "inline-block",
                              width: "auto",
                            }}
                          />{" "}
                          <GText
                            light
                            text={settings?.currency_symbol + product.price}
                            style={{
                              display: "inline-block",
                              width: "auto",
                              textDecoration: "line-through",
                            }}
                          />
                        </GView>
                      ) : (
                        <GText
                          theme
                          semi
                          text={settings?.currency_symbol + product.price}
                        />
                      )}
                    </GView>
                  ) : (
                    <>
                      {product.price != product.price_old ? (
                        <GView
                          style={{
                            padding: "10px 0 0",
                          }}
                        >
                          <GText
                            theme
                            semi
                            text={
                              settings?.currency_symbol +
                              product.price_discounted
                            }
                            style={{
                              display: "inline-block",
                              width: "auto",
                            }}
                          />{" "}
                          <GText
                            light
                            text={settings?.currency_symbol + product.price}
                            style={{
                              display: "inline-block",
                              width: "auto",
                              textDecoration: "line-through",
                            }}
                          />
                        </GView>
                      ) : (
                        <GText
                          theme
                          semi
                          text={settings?.currency_symbol + product.price}
                          style={{ padding: "10px 0 0" }}
                        />
                      )}
                      <GText
                        style={{
                          margin: "0 0 10px",
                          color: "#333",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          textWrap: "nowrap",
                        }}
                        text={product.title}
                      />
                    </>
                  )}
                </ProductDiv>
              </div>
            );
          })}
        </Slider>
      </div>

      <Modal
        isOpen={itemInfoVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setItemInfoVisible(false)}
        style={{
          content: {
            width: "450px",
            padding: "20px 0",
            // transform: 'translate(0%, -50%)'
          },
        }}
        contentLabel="Item info Modal"
      >
        <ProductInfo
          item={selectedProduct}
          itemInfoVisible={itemInfoVisible}
          setItemInfoVisible={setItemInfoVisible}
        />
      </Modal>
    </div>
  );
}
