import React, { useEffect, useContext, useState } from "react";
import "./App.css";
import { ImgUrl } from "./ApiService/services";
import Theme from "./contexts/theme";
import { useLocation, Routes, Route } from "react-router-dom";
import Modal from "react-modal";
import Header from "./Parts/header";
import Footer from "./Parts/footer";
import { StringsContext } from "./DataFactory/useStrings";
import { SettingsContext } from "./DataFactory/useSettings";
import { CartItemStore } from "./DataFactory/useCart";
import useLocalStorage from "./DataFactory/useLocalStorage";
import useWindowSize from "./DataFactory/useWindowSize";
import { ErrorBoundary } from "react-error-boundary";
import ErrorPage from "./errorView";
import NotFound from "./notFound";
import GView from "./Elements/GView";
import PrivacyPolicy from "./Pages/privacyPolicy";
import Terms from "./Pages/terms&Conditions";
import Offers from "./Pages/offers";
import Faqs from "./Pages/faqs";
import Profile from "./Pages/profile";
import DriverSignup from "./Pages/driverSignup";
import VendorSignup from "./Pages/vendorSignup";
import Home from "./Pages/home";
// import { initFacebookSdk } from "./socialLogin/facebookLoginSdk";
import { UserDefaultLocation } from "./contexts/userLocation";
import { UserDefaultAddress } from "./DataFactory/useDefaultAddress";
import Stores from "./Pages/stores";
import CourierOrderCreate from "./Pages/courierOrderCreate";
import Products from "./products/products";
import Checkout from "./Pages/checkout";
import AtmOrderCreate from "./Pages/atmOrderCreate";
import Coupons from "./Pages/coupons";

Modal.setAppElement("#root");

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <ErrorPage error={error} resetErrorBoundary={resetErrorBoundary} />
    </div>
  );
}

function App() {
  // initFacebookSdk();
  let { width } = useWindowSize();
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const { settings } = useContext(SettingsContext);
  const language = useContext(StringsContext);
  const [lang, setLang] = useLocalStorage("language");
  const [loadMap, setLoadMap] = useState(false);

  const loadGoogleMapScript = (callback) => {
    if (
      typeof window.google === "object" &&
      typeof window.google.maps === "object"
    ) {
      callback();
    } else {
      const googleMapScript = document.createElement("script");
      googleMapScript.type = "text/javascript";
      googleMapScript.src =
        `https://maps.googleapis.com/maps/api/js?key=` +
        process.env.REACT_APP_GOOGLE_KEY +
        `&libraries=geometry,places`;
      window.document.body.appendChild(googleMapScript);
      googleMapScript.addEventListener("load", callback);
    }
  };

  useEffect(() => {
    loadGoogleMapScript(() => {
      setLoadMap(true);
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [language[3]]);

  useEffect(() => {
    if (settings) {
      let localisation = settings?.localisation
        ? JSON.parse(settings?.localisation)
        : settings?.localisation;
      if (!lang) {
        setLang("en");
        setLang(localisation?.options[0] || "en");
      }
      setIsLoading(false);
    }
  }, [settings]);

  const isSettings = () => {
    if (settings && settings?.currency_symbol) {
      document.title = location?.state?.title
        ? location.state?.title + " | " + settings?.project_name
        : settings?.project_name || "Ambar";
      const favicon = document.getElementById("favicon");
      // setTimeout(function () {
      //   favicon.href = ImgUrl("web") + "/" + settings?.web?.web_favicon;
      // }, 800);
      return true;
    }
    return false;
  };

  var fallbackToStore = function () {
    let ua = navigator.userAgent.toLowerCase();
    console.log(ua.indexOf("android"));
    console.log(ua.indexOf("iphone"));
    //return;
    if (ua.indexOf("android") > -1) {
      window.location.replace(
        "https://play.google.com/store/apps/details?id=com.ambarapp.customer"
      );
    }
    if (ua.indexOf("iphone") > -1) {
      window.location.replace(
        "https://apps.apple.com/in/app/local-dukan/id6478523152"
      );
    }
  };
  var openApp = function () {
    let ua = navigator.userAgent.toLowerCase();
    console.log(ua.indexOf("android"));
    console.log(ua.indexOf("iphone"));
    //return;
    if (ua.indexOf("android") > -1) {
      window.location.replace(
        "intent://3.6.152.141:6063/#Intent;scheme=ambarapp;package=com.ambarapp.customer;end"
      );
    }
    if (ua.indexOf("iphone") > -1) {
      window.location.replace(
        "https://apps.apple.com/in/app/local-dukan/id6478523152"
      );
    }
  };
  var triggerAppOpen = function () {
    openApp();
    setTimeout(fallbackToStore, 800);
  };

  // useEffect(() => {
  //   triggerAppOpen();
  // }, []);

  return (
    <Theme>
      <CartItemStore>
        <UserDefaultLocation>
          {isSettings() === false ? (
            <>
              <div style={{ position: "relative", padding: "40px 0" }}>
                <Header />
              </div>
              <NotFound />
              <Footer />
            </>
          ) : (
            <UserDefaultAddress>
              {isLoading === true ? (
                <GView
                  style={{
                    padding: "25% 50%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <p>Loading...</p>
                </GView>
              ) : (
                <div
                  style={{
                    backgroundColor: "#fff",
                    display: "flex",
                    flexDirection: "column",
                    height: "100vh",
                  }}
                  id="webElement"
                >
                  <ErrorBoundary
                    key={location.pathname}
                    onError={(error, componentStack) => {
                      console.log(error);
                      console.log(componentStack?.componentStack);
                    }}
                    FallbackComponent={ErrorFallback}
                    onReset={() => {
                      window.location.reload(false);
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        padding: "40px 0",
                      }}
                    >
                      <Header
                        showCart={
                          location.pathname === "/checkout" ? false : true
                        }
                      />
                    </div>

                    <div
                      className="App-content"
                      style={{
                        flex: "1 1 auto",
                      }}
                    >
                      <Routes>
                        <Route exact path="/" element={<Home />}></Route>
                        <Route
                          path="/:classId/stores"
                          element={<Stores />}
                        ></Route>
                        <Route
                          path="/store/:storeId/products"
                          element={<Products />}
                        ></Route>
                        <Route path="checkout" element={<Checkout />}></Route>
                        <Route
                          path="/courier-order-create"
                          element={<CourierOrderCreate />}
                        ></Route>
                        <Route
                          path="/atm-order-create"
                          element={<AtmOrderCreate />}
                        ></Route>
                        <Route path="/not-found" component={NotFound}></Route>
                        <Route
                          path="privacy-policy"
                          element={<PrivacyPolicy />}
                        ></Route>
                        <Route path="terms" element={<Terms />}></Route>
                        <Route path="offers" element={<Offers />}></Route>
                        <Route path="coupons" element={<Coupons />}></Route>
                        <Route path="faqs" element={<Faqs />}></Route>

                        <Route path="profile/*" element={<Profile />}></Route>
                        {settings?.signup_agent === true && (
                          <Route
                            path="driver-signup"
                            element={<DriverSignup />}
                          ></Route>
                        )}
                        {settings.signup_vendor === true && (
                          <Route
                            path="vendor-signup"
                            element={<VendorSignup />}
                          ></Route>
                        )}
                      </Routes>
                    </div>
                  </ErrorBoundary>
                  <Footer />
                </div>
              )}
            </UserDefaultAddress>
          )}
        </UserDefaultLocation>
      </CartItemStore>
    </Theme>
  );
}

export default App;
