import React, { useState, useEffect, useContext } from "react";
import { ImgUrl, addDefaultSrc, ApiService } from "../ApiService/services";
import styled from "styled-components";
import GText from "../Elements/GText";
import GView from "../Elements/GView";
import GButton from "../Elements/GButton";
import StarRate from "../assets/images/icons/star-empty.png";
import DeliveryVan from "../assets/images/icons/truck.png";
import Timer from "../assets/images/icons/timer.png";
import { ReactComponent as Saved } from "../assets/images/icons/saved.svg";
import { ReactComponent as Unsaved } from "../assets/images/icons/unsaved.svg";
import { ReactComponent as Location } from "../assets/images/icons/location.svg";
import Modal from "react-modal";
import StoreReviews from "../Components/storeReviews";
import useWindowSize from "../DataFactory/useWindowSize";
import { StringsContext } from "../DataFactory/useStrings";
import { useEnqueueSnackbar } from "../DataFactory/useCustomSanckbar";

const CoverDiv = styled.div`
  display: flex;
  padding: 40px 25px;
  .favorite {
    margin-left: auto;
    svg {
      cursor: pointer;
      width: 25px;
      height: 25px;
      fill: #fff;
    }
  }
`;

const Div = styled.div`
  display: flex;
  flex-direction: row;

  .rating {
    margin: 0;
    flex: 1;
    display: flex;
    align-items: end;
    svg {
      width: 15px;
      height: 15px;
    }
    img {
      width: 17px;
      height: auto;
    }
  }

  &.column {
    flex-direction: column;
  }
  .openTill {
    background: ${({ theme }) => theme.body};
    height: fit-content;
    text-align: center;
    min-width: 68px;
    padding: 6px 10px;
    border-radius: 4px;
    p {
      color: #fff;
    }
  }
  .discount {
    background: ${({ theme }) => theme.body};
    color: #fff;
  }
`;

const TagView = styled.div`
  margin: 20px 0;
  display: flex;
  justify-content: center;
  gap: 10px;
  p {
    display: inline-block;
    padding: 3px 18px 4px;
    border-radius: 20px;
    width: auto;
    margin: 0;
    border: 1px solid ${({ theme }) => theme.body};

    &.disabled {
      border: 1px solid #aaa;
      color: #aaa;
    }
  }

  a {
    cursor: pointer;
    p {
      border: 1px solid ${({ theme }) => theme.body};
      background: ${({ theme }) => theme.body};
      color: #fff;
      display: flex;
      align-items: center;
      svg {
        fill: #fff;
        width: 14px;
        height: 14px;
      }
      &.disabled {
        background: transparent;
        border: 1px solid #ccc;
        color: #aaa;
        svg {
          fill: #ccc;
        }
      }
    }
  }
`;

const StoreInfo = (props) => {
  const [strings] = useContext(StringsContext);
  const { width } = useWindowSize();
  const enqueueSnackbar = useEnqueueSnackbar();
  const [storeData, setStoreData] = useState(props.data);
  const [srvisible, setSRVisible] = useState(false);
  const [favourite, setFavourite] = useState(props.data.favourite || false);

  useEffect(() => {
    setStoreData(props.data);
    setFavourite(props.data.favourite || false);
  }, [props.data]);

  const addToFavourite = () => {
    if (!props.settings?.user?.id) {
      props.onAddToFavourite();
      return;
    }
    ApiService({
      method: "POST",
      route: "c/store-wishlist",
      body: { store_id: storeData.id },
    }).then((response) => {
      console.log(response);
      if (response.data.status_code === 1) {
        setFavourite(!favourite);
      } else {
        enqueueSnackbar(response.data.message);
      }
    });
  };
  const removeFavourite = () => {
    ApiService({
      method: "DELETE",
      route: "c/store-wishlist",
      body: { store_id: storeData.id },
    }).then((response) => {
      console.log(response);
      if (response.data.status_code === 1) {
        setFavourite(!favourite);
      } else {
        enqueueSnackbar(response.data.message);
      }
    });
  };

  return (
    <>
      <GView
        style={{
          backgroundColor: "#f5f5f5",
          backgroundImage: `url(${ImgUrl("store")}/${storeData.cover_photo})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          minHeight:
            width > 767 && width <= 1500
              ? "220px"
              : width > 1500
              ? "240px"
              : "200px",
        }}
      >
        <CoverDiv>
          <GView className="favorite">
            {favourite == true ? (
              <Saved onClick={() => removeFavourite()} />
            ) : (
              <Unsaved
                onClick={() => {
                  addToFavourite();
                }}
              />
            )}
          </GView>
        </CoverDiv>
      </GView>
      <GView style={{ textAlign: "center" }}>
        <GView
          style={{
            padding: "20px",
            background: "#fff",
            borderRadius: "12px",
            boxShadow: "rgba(204, 204, 204, 0.52) 0px 0px 10px",
            textAlign: "left",
            width: width > 767 ? "80%" : "95%",
            margin: "-80px auto 10px",
            boxSizing: "border-box",
          }}
        >
          <Div>
            <img
              src={ImgUrl("store") + "/" + storeData.thumb_photo}
              alt={storeData.title}
              onError={(e) => addDefaultSrc(e, "store")}
              width="auto"
              height={width > 767 ? "100px" : "50px"}
            />
            <div
              className="info"
              style={{ margin: width > 767 ? "0 20px" : "0 15px", flex: 1 }}
            >
              <GText
                g3
                semi
                text={storeData.title}
                style={{
                  fontSize: width > 767 ? "21px" : "18px",
                  lineHeight: width > 767 ? "28px" : "22px",
                }}
              />
              {storeData?.min_order && storeData?.min_order > 0 ? (
                <GText
                  text={`Mínimo de compra ${props.settings.currency_symbol}${storeData.min_order}`}
                />
              ) : (
                ""
              )}
              {storeData?.promotion_display ? (
                <GText
                  semi
                  className="discount"
                  text={`${storeData.promotion_display}`}
                  style={{
                    padding: "2px 7px",
                    margin: "3px 0 0",
                    width: "auto",
                    fontSize: "14px",
                    display: "inline-block",
                  }}
                />
              ) : (
                ""
              )}
              <div style={{ margin: "5px 0" }}>
                <GText
                  med
                  text={storeData.address}
                  style={{
                    marginRight: "10px",
                    fontSize: width > 767 ? "15px" : "14px",
                    width:
                      props.branchesData && props.branchesData.length > 0
                        ? "auto"
                        : "100%",
                    display:
                      props.branchesData && props.branchesData.length > 0
                        ? "inline"
                        : "block",
                  }}
                />

                {props.branchesData && props.branchesData.length > 0 ? (
                  <GButton
                    variant="linkable"
                    style={{
                      margin: 0,
                      padding: "0",
                      textDecoration: "underline",
                      fontWeight: "500",
                    }}
                    onClick={() => props.onClick()}
                  >
                    {strings.w_select_another_branch}
                  </GButton>
                ) : (
                  ""
                )}
              </div>
              {width > 767 && (
                <GView
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "15px 0",
                  }}
                >
                  <p className="rating" style={{ alignItems: "center" }}>
                    <img src={StarRate} /> &nbsp;{" "}
                    {storeData.rating_display?.display}
                  </p>
                  <p className="rating">
                    <img src={DeliveryVan} style={{ width: "23px" }} /> &nbsp;{" "}
                    {storeData.delivery_fee_display}
                  </p>
                  <p className="rating">
                    <img src={Timer} /> &nbsp; {storeData.delivery_time_display}
                  </p>
                </GView>
              )}
            </div>
            <div
              className="openTill"
              style={{ padding: width > 767 ? "6px 10px" : "5px 8px" }}
            >
              <GText g6 text={strings.w_open_till} />
              <GText semi text={storeData.open_till_display} />
            </div>
          </Div>
          {width <= 767 && (
            <Div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "15px 0",
              }}
            >
              <p className="rating" style={{ alignItems: "center" }}>
                <img src={StarRate} /> &nbsp;{" "}
                {storeData.rating_display?.display}
              </p>
              <p className="rating">
                <img src={DeliveryVan} style={{ width: "23px" }} /> &nbsp;{" "}
                {storeData.delivery_fee_display}
              </p>
              <p className="rating">
                <img src={Timer} /> &nbsp; {storeData.delivery_time_display}
              </p>
            </Div>
          )}
        </GView>

        <TagView>
          <GText
            text={strings.w_delivery}
            className={
              storeData.order_type === "store_pickup" ? "disabled" : ""
            }
          />
          <GText
            text={strings.w_pickup}
            className={
              storeData.order_type === "home_delivery" ? "disabled" : ""
            }
          />
          {storeData.order_type === "home_delivery" ? (
            <a style={{ cursor: "auto" }}>
              <p className="disabled">
                <Location /> &nbsp;{strings.w_maps}
              </p>
            </a>
          ) : (
            <a
              role="link"
              target="_blank"
              rel="noopener noreferrer"
              href={`https://www.google.com/maps?q=${storeData.latitude},${storeData.longitude}`}
            >
              <p>
                <Location /> &nbsp;{strings.w_maps}
              </p>
            </a>
          )}
        </TagView>
      </GView>
      <Modal
        isOpen={srvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setSRVisible(false)}
        style={{
          content: {
            width: "400px",
            padding: "20px 0",
            overflow: "hidden",
          },
        }}
        contentLabel="Store Review Modal"
      >
        <StoreReviews
          srvisible={srvisible}
          setSRVisible={setSRVisible}
          store={storeData}
        />
      </Modal>
    </>
  );
};

export default StoreInfo;
