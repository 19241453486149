import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { ImgUrl, addDefaultSrc, getAmount } from "../ApiService/services";
import { ReactComponent as Add } from "../assets/images/icons/add.svg";
import { ReactComponent as Remove } from "../assets/images/icons/minus.svg";
import { CartContext } from "../DataFactory/useCart";
import GButton from "./GButton";
import GText from "./GText";

const QuantityDiv = styled.div`
  display: inline-flex;
  background: #fff;
  align-items: center;
  justify-content: center;
  padding: 0.45rem 0.6rem;
  width: 72px;
  border: 1px solid ${({ theme }) => theme.body};
  color: ${({ theme }) => theme.body};
  svg {
    height: 12px;
    width: 12px;
    fill: ${({ theme }) => theme.body};
  }
`;

const AddDiv = styled.div`
  svg {
    fill: ${({ theme }) => theme.body};
    font-size: 0.56rem;
    margin: 0 -11px;
    position: absolute;
    margin-top: 2px;
  }
`;

export default function GProductThemeView(props) {
  const { item, ind, settings, strings } = props;
  const { itemCart } = useContext(CartContext);

  useEffect(() => {
    // console.log(itemCart);
  }, [itemCart]);

  return (
    <div
      style={{
        padding: "15px 0",
        borderBottom:
          ind + 1 < props.sub?.products?.length ? "1px solid #f2f2f2" : "0",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        width: "100%",
      }}
      key={ind}
      onClick={() => {
        props.setSelectedProduct(item);
        props.setItemInfoVisible(true);
      }}
    >
      <div
        style={{
          width: "calc(100% - 120px)",
          marginRight: "10px",
        }}
      >
        <GText
          g4
          text={item.title}
          style={{
            letterSpacing: "0",
            fontSize: "16.5px",
          }}
        />
        {item.unit && (
          <GText
            g5
            med
            text={item.unit}
            style={{
              padding: "5px 0 0",
              fontSize: "14px",
            }}
          />
        )}
        {item.price !== item.price_old ? (
          <>
            <GText
              theme
              text={getAmount(item.price_discounted)}
              style={{
                padding: "5px 0",
                display: "inline-block",
                width: "auto",
              }}
            />{" "}
            <GText
              light
              text={getAmount(item.price)}
              style={{
                padding: "5px 0",
                display: "inline-block",
                width: "auto",
                textDecoration: "line-through",
              }}
            />
          </>
        ) : (
          <GText
            theme
            text={getAmount(item.price)}
            style={{ padding: "5px 0" }}
          />
        )}
        <GText
          g6
          light
          text={item.description}
          style={{ letterSpacing: "0.3px" }}
        />
      </div>
      <div style={{ width: "110px", textAlign: "center" }}>
        {item.icon ? (
          <img
            src={ImgUrl("item") + "/" + item.icon}
            alt={item.title}
            onError={(e) => addDefaultSrc(e, "item")}
            style={{
              maxWidth: "100%",
              height: "90px",
              objectFit: "cover",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            onClick={() => {
              props.setSelectedProduct(item);
              props.setItemInfoVisible(true);
            }}
            width="auto"
            height="90px"
          />
        ) : (
          ""
        )}
        <div
          style={{
            marginTop: item.icon ? "-20px" : "0",
          }}
        >
          {item?.next_available_display ? (
            <AddDiv>
              <GButton
                variant="outlined"
                children={item.next_available_display}
                disabled
                style={{
                  borderRadius: "0",
                  background: "#fff",
                  color: "#ccc",
                  border: "1px solid #d2d2d2",
                  fontSize: "11.5px",
                  lineHeight: "15px",
                  letterSpacing: "0.2px",
                  padding: "0.2rem 0.4rem",
                  fontWeight: "400",
                }}
              />
            </AddDiv>
          ) : itemCart && itemCart.some((c) => c.id === item.id) ? (
            itemCart
              .filter((c) => c.id === item.id)
              .slice(0, 1)
              .map((c, i) => {
                return (
                  <QuantityDiv key={i}>
                    <Remove
                      onClick={(e) => {
                        e.stopPropagation();
                        props.decreaseQuantity(c);
                      }}
                    />
                    <span
                      style={{
                        padding: "1px 10px 0",
                        fontSize: "14px",
                      }}
                    >
                      {itemCart
                        .filter((c) => c.id === item.id)
                        .map((o) => o.quantity)
                        .reduce((a, c) => a + c, 0)}
                    </span>
                    <Add
                      onClick={(e) => {
                        e.stopPropagation();
                        if (item.variants_available === false) {
                          //item.variants = [];
                          props.addProduct(c, c.variants);
                        } else {
                          props.setSelectedProduct(c);
                          props.setOrderVarVisible(true);
                        }
                      }}
                    />
                  </QuantityDiv>
                );
              })
          ) : (
            <AddDiv>
              {item.status == "0" ? (
                <GButton
                  variant="outlined"
                  children={strings?.w_not_available}
                  disabled
                  style={{
                    borderRadius: "0",
                    color: "#a2a2a2",
                    border: "1px solid #d2d2d2",
                    fontSize: "14px",
                    padding: "0.6rem 1rem",
                    background: "#fff",
                  }}
                />
              ) : settings?.product_stock == "1" &&
                (item.unlimited == 0) & (item.stock == 0) ? (
                <GButton
                  variant="outlined"
                  children={strings?.w_out_of_stock}
                  disabled
                  style={{
                    borderRadius: "0",
                    color: "#a2a2a2",
                    border: "1px solid #d2d2d2",
                    fontSize: "14px",
                    padding: "0.6rem",
                    background: "#fff",
                    width: "95%",
                  }}
                />
              ) : (
                <React.Fragment>
                  <GButton
                    variant="outlined"
                    children={strings?.w_add}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (
                        settings?.product_stock == "1" &&
                        item.unlimited == 0 &&
                        item.stock == 0
                      ) {
                        return false;
                      }
                      if (item.variants_available === false) {
                        item.variants = [];
                        props.addProduct(item);
                      } else {
                        props.setSelectedProduct(item);
                        props.setOrderVarVisible(true);
                      }
                    }}
                    style={{
                      padding: "0.45rem",
                      width: "95%",
                      borderRadius: "0",
                      background: "#fff",
                      cursor:
                        settings?.product_stock == "1" &&
                        (item.unlimited == 0) & (item.stock == 0)
                          ? "auto"
                          : "pointer",
                    }}
                  />
                  {item.variants_available === true ? (
                    <Add
                      style={{ cursor: "pointer", width: 10, height: 10 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (
                          settings?.product_stock == "1" &&
                          (item.unlimited == 0) & (item.stock == 0)
                        ) {
                          return false;
                        }
                        props.setSelectedProduct(item);
                        props.setOrderVarVisible(true);
                      }}
                    />
                  ) : (
                    ""
                  )}
                </React.Fragment>
              )}
            </AddDiv>
          )}
        </div>
      </div>
    </div>
  );
}
