import React, { useContext, useEffect, useState } from "react";
import GView from "../Elements/GView";
import useDataFactory from "../ApiService/useDataFactory";
import useWindowSize from "../DataFactory/useWindowSize";
import { UserLocContext } from "../contexts/userLocation";
import { StringsContext } from "../DataFactory/useStrings";
import GTabs from "../Elements/gTabs";
import GInfo from "../Elements/gInfo";
import GOfferProduct from "../Elements/gOfferProduct";
import { Link } from "react-router-dom";
import { Filter } from "../productSearchComponents/selectListFilter";

export default function Offers() {
  const [initialLoading, setInitialLoading] = useState(false);
  const { width } = useWindowSize();
  const { userLocation } = useContext(UserLocContext);
  const [strings] = useContext(StringsContext);
  const [typeValue, setTypeValue] = useState("");
  const [word, setWord] = useState("");
  const { loading, Shimmer, Placeholder, data, fullData, refreshData } =
    useDataFactory("product_by_offer", false, {
      geofence: "",
      location: userLocation?.lat
        ? userLocation.lat + "," + userLocation.lng
        : "",
      keyword: word || "",
    });

  useEffect(() => {
    if (loading === false)
      setTimeout(function () {
        setInitialLoading(false);
      }, 2000);
  }, [loading]);

  useEffect(() => {
    if (initialLoading === false && loading === false) {
      refreshData({
        geofence: "",
        location: userLocation?.lat
          ? userLocation.lat + "," + userLocation.lng
          : "",
        keyword: word || "",
      });
    }
  }, [word, userLocation]);

  useEffect(() => {
    setTypeValue(
      fullData?.classes && fullData?.classes.length > 0
        ? fullData?.classes[0]?.id
        : ""
    );
  }, [fullData]);

  const handleChangeTab = (newVal) => {
    console.log(newVal);
    setTypeValue(newVal);
  };

  return (
    <div className="main-content" style={{ marginTop: "30px" }}>
      <GView
        style={{
          display: "flex",
          alignItems: width > 767 ? "center" : "start",
          flexDirection: width > 767 ? "row" : "column",
        }}
      >
        <h1 style={{ margin: "10px 0 5px" }}>{strings?.w_offers}</h1>

        <Filter
          value={word}
          handleChange={(e) => setWord(e || "")}
          placeholder={strings?.w_search_products}
          initialLoading={initialLoading}
          mainStyle={{
            height: "fit-conntent",
            marginLeft: "auto",
            width: width > 767 ? "300px" : "97%",
          }}
        />
      </GView>
      <GView
        style={{
          boxShadow: "0 0 10px #cccccc85",
          padding: "20px",
          margin: "20px 0",
        }}
      >
        <GTabs
          routes={fullData?.classes || []}
          active={typeValue}
          key_value={"id"}
          key_title={"title"}
          type="multiple"
          onClick={handleChangeTab}
        ></GTabs>

        <GView style={{ padding: "20px 0" }}>
          {loading === true ? (
            <Shimmer />
          ) : fullData.stores &&
            fullData.stores.filter((c) =>
              c.classes.includes(JSON.stringify(typeValue))
            ).length > 0 ? (
            <GView>
              {fullData.stores
                .filter((c) => c.classes.includes(JSON.stringify(typeValue)))
                .map((s, ind) => {
                  return (
                    <GView key={ind} style={{ padding: "15px 0" }}>
                      <Link
                        to={{ pathname: `/store/${s.id}/products` }}
                        state="Product"
                        style={{ cursor: "pointer" }}
                      >
                        <GInfo
                          title={`${s?.title}`}
                          subtitle={
                            s.promotion_display
                              ? `${s.promotion_display}`
                              : `Delivery Cost : ${s.delivery_fee_display} • ${s.delivery_time_display}`
                          }
                          subtitle2={
                            s.promotion_display
                              ? `Delivery Cost : ${s.delivery_fee_display} • ${s.delivery_time_display}`
                              : ""
                          }
                          photo={s?.thumb_photo}
                          imgType={"store"}
                          imgStyle={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "5px",
                          }}
                          subtitleClass={s.max_discount ? "tagSubtitle" : ""}
                          subtitleStyle={{ fontSize: "14px" }}
                          subtitleStyle2={{ fontSize: "14px", color: "#555" }}
                        />
                      </Link>

                      {data &&
                        data.length > 0 &&
                        data.filter((d) => d.store_id == s.id).length > 0 && (
                          <GView
                            style={{
                              marginTop: "10px",
                              display: "grid",
                              gridTemplateColumns:
                                width <= 350
                                  ? "repeat(auto-fit, minmax(300px, 1fr))"
                                  : width > 350 && width <= 767
                                  ? "1fr 1fr"
                                  : width > 767 && width <= 991
                                  ? "1fr 1fr 1fr"
                                  : width > 991 && width <= 1200
                                  ? "1fr 1fr 1fr 1fr"
                                  : width > 1200 && width <= 1500
                                  ? "1fr 1fr 1fr 1fr 1fr"
                                  : width > 1500 && width <= 1800
                                  ? "1fr 1fr 1fr 1fr 1fr 1fr"
                                  : width > 1800
                                  ? "1fr 1fr 1fr 1fr 1fr 1fr"
                                  : "repeat(auto-fit, minmax(250px, 1fr))",
                              gridGap: "20px",
                            }}
                          >
                            {data
                              .filter((d) => d.store_id == s.id)
                              .map((p, i) => {
                                return <GOfferProduct data={p} key={i} />;
                              })}
                          </GView>
                        )}
                    </GView>
                  );
                })}
            </GView>
          ) : (
            <Placeholder />
          )}
        </GView>
      </GView>
    </div>
  );
}
